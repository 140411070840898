type OfferDetailLine = {
  label: string;
  talent: boolean | null;
  team: boolean | null;
  academy: boolean | null;
};

export function useOfferDetails() {
  const sections: { title: string; lines: OfferDetailLine[] }[] = [
    {
      title: "Trainers",
      lines: [
        {
          label: "Prochaines sessions",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Trainer Catalogue",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Trainers à la Une",
          talent: false,
          team: null,
          academy: true,
        },
        {
          label: "Trainers internes",
          talent: false,
          team: false,
          academy: true,
        },
      ],
    },
    {
      title: "Session formats",
      lines: [
        {
          label: "Inter-entreprise",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Intra-entreprise",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Masterclass",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Mentoring 1-1",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Anchoring",
          talent: false,
          team: false,
          academy: true,
        },
      ],
    },
    {
      title: "Personnalisation",
      lines: [
        {
          label: "Exemples et exercices",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Call de préparation",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Création de cours",
          talent: false,
          team: null,
          academy: true,
        },
        {
          label: "Skill & needs analysis",
          talent: false,
          team: false,
          academy: true,
        },
        {
          label: "Audit de compétences",
          talent: false,
          team: false,
          academy: true,
        },
        {
          label: "Accompagnement individuel",
          talent: false,
          team: false,
          academy: true,
        },
        {
          label: "Programme co-brandé",
          talent: false,
          team: false,
          academy: true,
        },
      ],
    },
    {
      title: "Communication et marque employeur",
      lines: [
        {
          label: "Certificat Pollen",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Page cours dédiée",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Images à partager",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Aide au community management",
          talent: false,
          team: false,
          academy: true,
        },
        {
          label: "Certificat d’Academy Co-brandé",
          talent: false,
          team: false,
          academy: true,
        },
        {
          label: "Vidéos",
          talent: false,
          team: false,
          academy: true,
        },
      ],
    },
    {
      title: "Service client",
      lines: [
        {
          label: "Email et téléphone",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Account Manager dédié",
          talent: null,
          team: true,
          academy: true,
        },
        {
          label: "Support docs réglementaires",
          talent: true,
          team: true,
          academy: true,
        },
      ],
    },
    {
      title: "Produit",
      lines: [
        {
          label: "Espace Learner",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Support partagé",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Replay vidéo",
          talent: false,
          team: null,
          academy: true,
        },
        {
          label: "Espace Hub",
          talent: null,
          team: true,
          academy: true,
        },
        {
          label: "Invitations personnalisées",
          talent: false,
          team: true,
          academy: true,
        },
        {
          label: "Intégration SSO-LMS",
          talent: false,
          team: false,
          academy: null,
        },
      ],
    },
    {
      title: "Mesure d’impact",
      lines: [
        {
          label: "Satisfaction",
          talent: true,
          team: true,
          academy: true,
        },
        {
          label: "Personnalisation questionnaire",
          talent: false,
          team: null,
          academy: true,
        },
      ],
    },
  ];

  return { sections };
}
